<template>
  <div class="modal is-medium is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Asignar usuario a un grupo</p>
        <button class="delete" aria-label="close" type="reset" @click="close()"></button>
      </header>
      <section class="modal-card-body">
        <div class="field-body">
          <div class="field">
            <div class="select is-fullwidth">
              <select
                class="input"
                type="text"
                v-model="groupId"
                name="groupId"
                :class="{ 'is-danger': vverrors.first('groupId') }"
                v-validate="'required'"
              >
                <option :value="null" disabled> Selecciona una opción </option>
                <template v-for="group in groupsList">
                  <option :key="group.id" :value="group.id"> {{group.name}} </option>
                </template>
              </select>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div class="field is-grouped">
          <div class="control">
            <button class="button is-link is-light" :disabled="loading" @click="close()">Cerrar</button>
          </div>
          <div class="control">
            <button class="button is-info" :class="{ 'is-loading': loading }" :disabled="loading" @click="addGroupToUser">Guardar</button>
          </div>
        </div>
    </footer>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ModalGroups',
  props: {
    close: {
      type: Function,
      default: () => {}
    },
    userGroupsList: {
      type: Array
    },
    addGroup: {
      type: Function,
      default: () => {}
    },
    loadingAdd: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      groupId: null,
      showform: false,
      groupsList: []
    }
  },
  methods: {
    ...mapActions(['getUsergroups']),
    async addGroupToUser () {
      this.loading = true
      if (await this.$validator.validateAll()) {
        const response = await this.addGroup(this.groupId)
        if (response) {
          this.close()
        }
        this.loading = false
      }
    }
  },
  beforeMount () {
    this.getUsergroups()
      .then(response => {
        this.groupsList = response
      })
  }
}
</script>
